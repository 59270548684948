import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from "@angular/core";
import {
  $$,
  AggregateId,
  myRequestAnimationFrameNoAngular,
  mySetTimeoutNoAngular,
  None,
  ResponsiveWidthMonitor,
  ScreenInstanceId,
  Some, toastr,
  tryToFocusElement
} from "@utils";
import {
  ComponentErrorViewModel,
  ComponentValidationErrorViewModel,
  ContainerChildrenHandler,
  ScreenInstanceService,
  ScreenViewModel,
} from "./";
import {
  I18nService,
  NavigationService,
  ScreenInstanceSharedService,
  ServerEventsService,
  UserSettingsStateService,
  VariableTextPreviewService,
} from "@shared";
import {
  BusinessEntitySharedService,
  EmailsSharedService,
  FilePreviewRequest,
  FilesSharedService,
  ProcessEdgeId,
  ScreenExternalEventBus
} from "@shared-model";
import {Observable, Subscription} from "rxjs";
import {ScreenCommonQueryService} from "@screen-common";

@Component({
  selector: 'my-screen',
  templateUrl: './screen.component.html',
  host: {
    "[ngClass]": "'screen'",
  }
  //styleUrls: ['./screen.component.scss']
})
export class ScreenComponent implements OnInit, OnDestroy {

  readonly children = new ContainerChildrenHandler();

  _screenInstanceId: ScreenInstanceId|undefined;
  viewModel!: ScreenViewModel;
  @Input() terminateOnDestroy: "always"|"never"|"ifNotKeepAlive" = "ifNotKeepAlive";
  @Input() allowDebug: boolean = false;
  @Output() onNotFound = new EventEmitter<void>();

  _readOnly: boolean = false;
  screenTerminated?: EventEmitter<ScreenInstanceId>;
  private subscriptions: Array<Subscription> = [];

  @Input() set readOnly(value: boolean) {this._readOnly = value; this.readOnlyChanged();}
  get readOnly(): boolean {return this._readOnly}

  @Input() submitRequest?: Observable<ProcessEdgeId>;
  @Input() submitted: EventEmitter<void> = new EventEmitter<void>(); // input as it is handled by SharedScreenComponent

  @Input() filePreviewRequest: EventEmitter<FilePreviewRequest> = new EventEmitter<FilePreviewRequest>(); // input as it is handled by SharedScreenComponent
  @Input() filePreviewHandledExternally: boolean = false;

  @Input() focusOnFirstInput: boolean = false;

  screenExternalContextIdChanged?: EventEmitter<AggregateId>;

  @Input() eventBus: ScreenExternalEventBus = new ScreenExternalEventBus();

  @Input() externalDebugButton: boolean = false;

  private submitRequestSubscription?: Subscription;

  private initiated = false;

  responsive = ResponsiveWidthMonitor.of("screen", this.viewContainerRef).onRemWidth((width) => {
    this.viewModel.setMobileDesktop(width < 40, width >= 40 && width < 70, width >= 70);
  });

  checkResponsive?: Observable<void>;

  constructor(private readonly screenInstanceService: ScreenInstanceService,
              private readonly screenInstanceSharedService: ScreenInstanceSharedService,
              private readonly serverEventsService: ServerEventsService,
              private readonly viewContainerRef: ViewContainerRef,
              private readonly screenCommonQueryService: ScreenCommonQueryService,
              private readonly filesSharedService: FilesSharedService,
              private readonly emailsSharedService: EmailsSharedService,
              private readonly businessEntitySharedService: BusinessEntitySharedService,
              private readonly i18nService: I18nService,
              private readonly userSettingsService: UserSettingsStateService,
              private readonly navigationService: NavigationService,
              private readonly variableTextPreviewService: VariableTextPreviewService) {

    this.eventBus.on(this.eventBus.screenExternalContextIdChanged, (contextId: AggregateId) => {
      if(this.screenExternalContextIdChanged) {
        this.screenExternalContextIdChanged.emit(contextId);
      }
    });


    this.eventBus.on(this.eventBus.screenTerminated, (screenInstanceId: ScreenInstanceId) => {
      if(this.screenTerminated) {
        this.screenTerminated.emit(screenInstanceId);
      }
    });

  }

  @Input() set screenInstanceId(screenInstanceId: ScreenInstanceId|undefined) {
    this._screenInstanceId = screenInstanceId;
    if(this.initiated) {
      this.init();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  private init() {

    this.initiated = true;

    if(this.checkResponsive) {
      this.subscriptions.push(this.checkResponsive.subscribe(() => {
        this.responsive.check();
      }));
    }

    if(this._screenInstanceId === undefined) {
      throw new Error("No screenInstanceId");
    } else {

      if(this.submitRequest) {
        this.submitRequestSubscription = this.submitRequest.subscribe((edgeId) => {
          this.viewModel.submit(edgeId, () => {
            this.submitted.emit();
          });
        });
      }


      this.screenCommonQueryService.loadSkins().then((skins) => {

        if(this._screenInstanceId === undefined) {
          throw new Error("No screenInstanceId");
        } else {



          const filesPreviewCallback = this.filePreviewHandledExternally ? Some((files: Array<any>, index: number) => {
            this.filePreviewRequest.emit({files: files, index: index});
          }) : None();


          this.viewModel = new ScreenViewModel(this.viewContainerRef.element.nativeElement, this._screenInstanceId, this.terminateOnDestroy,
            this.eventBus,
            this.screenInstanceService, this.screenInstanceSharedService, this.serverEventsService, this.filesSharedService, this.emailsSharedService,
            this.businessEntitySharedService, this.i18nService, this.userSettingsService, this.navigationService, this.variableTextPreviewService,
            this.allowDebug, this.readOnly, filesPreviewCallback, () => {
              this.onNotFound.emit();
            }, skins);

          if(!this._readOnly && this.focusOnFirstInput) {
            myRequestAnimationFrameNoAngular(() => {
              this.focusFirstInput();
            });
          }

        }
      });
    }
  }

  ngOnDestroy(): void {
    if(this.viewModel !== undefined) {
      this.viewModel.destroy();
    }
    if(this.submitRequestSubscription) {
      this.submitRequestSubscription.unsubscribe( );
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private readOnlyChanged() {
    if(this.viewModel) {
      this.viewModel.setReadOnly(this._readOnly);

      if(!this._readOnly && this.focusOnFirstInput) {
        myRequestAnimationFrameNoAngular(() => {
          this.focusFirstInput();
        });
      }
    }
  }

  focusFirstInput(tryCount = 0) {
    const container = $$(this.viewContainerRef);
    container.find("adjusting-text-input:not([disabled])").forEach($ta => {
      if($ta.getAsHtmlElement().innerText == "") {
        tryToFocusElement(<HTMLInputElement>$ta.getAsHtmlElement());
      }
    });

    if(tryCount < 5) {
      mySetTimeoutNoAngular(() => this.focusFirstInput(tryCount + 1), 100);
    }
  }


  showValidationError(event: MouseEvent, error: ComponentErrorViewModel) {
    const component = $$(this.viewContainerRef).findAll(".ScreenComponent"+error.componentId);

    component.forEach(c => {
      c.scrollIntoView();

      mySetTimeoutNoAngular(() => {
        c.addClass("blinkError");
        mySetTimeoutNoAngular(() => {
          c.removeClass("blinkError");
        }, 2000);
      }, 50);
    })

  }
}
