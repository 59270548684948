import {TableContainerViewModel} from "../..";
import {Component, Input} from "@angular/core";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-table-container',
  templateUrl: './table-container.component.html',
  host: {
    "[class]": "'componentBody TableContainer ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.css",
    "[class.withHeader]": "viewModel.headerVisible",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class TableContainerComponent  {
  @Input({required:true}) viewModel!: TableContainerViewModel;

  readonly children = new ContainerChildrenHandler();

  trackByIndex(index: number, element: any) {
    return index;
  };

}


