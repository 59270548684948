<div class="screenInstance screen" *ngIf="viewModel">
  <button class="debugButton" (click)="viewModel.toggleDebugInfo()"
          *ngIf="viewModel.debugAvailable && !externalDebugButton"
          [my-tooltip]="{i18n: 'Debug', position: 'left'}"><i class="mi-info"></i></button>
  <div class="screenCanvas childrenPlain" *ngIf="!viewModel.debugInfoVisible"><div
    *ngFor="let component of viewModel.mainContainerViewModel" class="ScreenComponent ScreenComponent{{component.id}} {{component.errorClass}}"
    [class.nonVisibleComponent]="component.nonVisibleComponent"
    [class.mobile]="responsive.mobile"
    [class.desktop]="responsive.desktop"
    [style]="component.positionCss">
    <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
      <div class="componentLabel" *ngIf="component.label && component.label.visible">
        <component-label [viewModel]="component.label" />
      </div>
    <!--              SHARED -->

    <ng-container *ngComponentOutlet="children.componentOf(component);inputs:children.inputsOf(component)" />

    <div class="errorsInfo" *ngIf="component.error">
      <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
    </div>

    <div class="inProgressGlass" *ngIf="component.inProgress"></div>
    <!--              SHARED -->
    </div>
  </div>
</div>


<!--  <my-file-viewer [visible]="viewModel.screenFileViewerVisible"-->
<!--  [files]="viewModel.screenViewableFiles"-->
<!--  current-file-index="viewModel.screenFileViewerIndex"-->
<!--  on-close="viewModel.onScreenCloseFileViewer()"-->
<!--  full-screen-only="true" />-->

  <my-file-viewer [visible]="viewModel.screenFileViewerVisible"
                  [files]="viewModel.screenViewableFiles"
                  [currentFileIndex]="viewModel.screenFileViewerIndex"
                  (close)="viewModel.onScreenCloseFileViewer()"
                  [fullScreenOnly]="true" />

  <my-modal [backgroundVisible]="false" cssClass="allScreenErrorsInfo" [visible]="viewModel.validationErrors.length > 0 || viewModel.internalErrors.length > 0"
            horizontalPosition="end" verticalPosition="bottom">
    <ng-container *ngIf="viewModel.validationErrors.length > 0 || viewModel.internalErrors.length > 0">
      <button class="minimize" (click)="viewModel.toggleErrorsExpanded()">
        <i *ngIf="!viewModel.errorsExpanded" class="mi-navigate-up"></i>
        <i *ngIf="viewModel.errorsExpanded" class="mi-navigate-down"></i>
      </button>
      <div *ngIf="viewModel.errorsExpanded" class="theme-selectable-text">
        <div class="errorsLabel" *ngIf="viewModel.internalErrors.length > 0">Errors:</div>
        <button class="errorInfo" *ngFor="let error of viewModel.internalErrors" (click)="showValidationError($event, error)">
          <span class="definitionId">{{error.definitionId}}</span>
          <span class="refId">{{error.refId}}</span>
          <div class="componentName"><i class="errorsIcon mi-sensor-alert"></i>{{error.componentName}} :</div>
          <div class="errorMessage" *ngFor="let err of error.errors">{{err.name}}: {{err.message}}</div>
        </button>
        <div class="errorsLabel" *ngIf="viewModel.validationErrors.length > 0">{{'screen_incorrect_form_values' | i18n}}:</div>
        <button class="errorInfo" *ngFor="let error of viewModel.validationErrors" (click)="showValidationError($event, error)">
          <div class="componentName">{{error.componentName}}:</div>
          <div class="errorMessages">
            <div class="errorMessage" *ngFor="let err of error.errors">{{err}}</div>
          </div>
        </button>
      </div>
    </ng-container>
  </my-modal>

  <my-modal cssClass="screenDebugInfoPopup" [(visible)]="viewModel.debugInfoVisible" [backgroundVisible]="true" [closeOnBackgroundClick]="true">
    <my-screen-debug *ngIf="viewModel.debugInfoVisible && viewModel.debugInfo" [viewModel]="viewModel.debugInfo" />
  </my-modal>

</div>
