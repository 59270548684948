import {Component, Input, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import {ContainerChildrenHandler, ViewSwitcherContainerViewModel} from "../..";
import {NavigationService} from "@shared";
import {$$, getElementPosition} from "@utils";
import {Subscription} from "rxjs";

@Component({
  selector: 'my-view-switcher-container',
  templateUrl: './view-switcher-container.component.html',
  host: {
    "[class]": "'componentBody ViewSwitcherContainer ' + viewModel.customCssClass",
    "[style]": "viewModel.css + ' ' + viewModel.sizeCss",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class ViewSwitcherContainerComponent implements OnInit, OnDestroy {
  @Input({required:true}) viewModel!: ViewSwitcherContainerViewModel;
  private subscription?: Subscription;

  readonly children = new ContainerChildrenHandler();

  constructor(private readonly navigationService: NavigationService,
              private readonly viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.subscription = this.viewModel.scrollRequestObservable.subscribe(scrollRequest => {
      this.scrollRequested();
    });
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private scrollRequested() {
    if(this.viewModel.activeView) {

      const screenPosition = getElementPosition($$(this.viewContainerRef).findParentWithClassOrError("screenSpace"));
      const elementPosition = getElementPosition($$(this.viewContainerRef));
      if(elementPosition.y < screenPosition.y) {
        $$(this.viewContainerRef).findOrError(".childrenPlain").scrollIntoView({block: "start"});
      }
    }
  }
}

