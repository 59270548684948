import {Component, OnInit} from "@angular/core";
import {NavigationService, SessionEventBus, SessionServiceProvider, UserSettingsStateService} from "@shared";
import {global, None, toastr} from "@utils";

@Component({
  selector: "my-login-page",
  templateUrl: "./login-page.component.html"
})
export class LoginPageComponent implements OnInit {

  entraIdLogins: Array<{name: string, identifier: string}> = [];
  externalLoginInProgress: boolean = false;

  constructor(readonly userSettingsService: UserSettingsStateService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly navigationService: NavigationService,
              readonly sessionEventBus: SessionEventBus) {
    sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
      this.onUserLoggedInInOtherPage();
    });
  }

  ngOnInit(): void {

    this.entraIdLogins = global.config.entraIdSSO;

    const stored = localStorage.getItem("entraIdLogin");
    if(stored) {
      this.externalLoginInProgress = true;
      this.loginUsingEntraId(stored);
    }
  }


  loginUsingEntraId(identifier: string) {
    this.sessionServiceProvider.getSessionService(sessionService => {
      sessionService.getEntraIdLoginIrl(identifier, (redirectUrl) => {
        console.log("redirect to " + redirectUrl);
        setTimeout(() => {
          window.location.href = redirectUrl;
        });
      }, () => {
        this.navigationService.navigateToLoginPage()
        toastr.info("Error while connecting to Entra ID");
      });
    })
  }

  private onUserLoggedInInOtherPage() {
    this.sessionServiceProvider.getSessionService(sessionService => {
      if (sessionService.lastRequestedUrl.getOrElse("").indexOf("/admin") >= 0) {
        throw new Error("Logging to admin not yet implemented");
        // this.navigationService.navigateToLastRequestedPageOrMainPage();
        // $location.url("/admin/organizations");
      } else {
        this.navigationService.navigateToLastRequestedPageOrMainPage(["/login"]);
        sessionService.lastRequestedUrl = None();
      }
    });
  }
}
